import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-column" }
const _hoisted_2 = { class: "m-0 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString($props.filterKey) + ":", 1),
    _createVNode(_component_Dropdown, {
      modelValue: $props.modelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($props.modelValue) = $event)),
      options: $props.options,
      placeholder: "Select an option",
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $props.modelValue))),
      showClear: true
    }, null, 8, ["modelValue", "options"])
  ]))
}