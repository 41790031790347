import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe970294"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 1,
  class: "mr-1"
}
const _hoisted_3 = { class: "mr-2 filter-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("div", {
    title: $props.field,
    class: _normalizeClass([$props.field, "p-2 mt-2 filter-tag flex align-items-center"])
  }, [
    ($setup.icon)
      ? (_openBlock(), _createBlock(_component_mdicon, {
          key: 0,
          class: "mr-1",
          name: $setup.icon,
          size: 20
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    (!$setup.icon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($props.operator), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, _toDisplayString($props.value), 1),
    _createVNode(_component_mdicon, {
      class: "close-button",
      name: "close",
      onClick: $setup.removeTag,
      size: 18
    }, null, 8, ["onClick"])
  ], 10, _hoisted_1))
}