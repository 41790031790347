
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
  reactive,
} from "vue"
import ResultSet from "@/models/ResultSet"
import { RouterLink, useRoute, useRouter } from "vue-router"
import ComputerService from "@/services/ComputerService"
import ComputerGroupsDropdown from "@/components/computers/tools/ComputerGroupsDropdown.vue"
import Button from "primevue/button"
import OverlayPanel from "primevue/overlaypanel"
import CustomDataTable from "../data/CustomDataTable.vue"
import useTimeUtils from "@/utils/time"
import ComputerRunConfiguration from "@/components/computers/tools/ComputerRunConfiguration.vue"
import ComputerSyncTime from "@/components/computers/tools/ComputerSyncTime.vue"
import ComputerUpdateTime from "@/components/computers/tools/ComputerUpdateTime.vue"
import LinkLabel from "../navigation/LinkLabel.vue"
import ComputerToolkitVersion from "./tools/ComputerToolkitVersion.vue"
import ToolkitAutoComplete from "../releases/toolkit/ToolkitAutoComplete.vue"
import ComputerForm from "./ComputerForm.vue"
import Column from "primevue/column"
import DataTable from "primevue/datatable"
import Dialog from "primevue/dialog"
import Dropdown from "primevue/dropdown"
import FilterField from "../data/FilterField.vue"
import ReleasesService from "@/services/ReleasesService"
import FilterContextMenu from "../data/FilterContextMenu.vue"
import FilterTag from "../data/FilterTag.vue"
import ComputerCollectionFieldsFilter from "@/components/computers/tools/ComputerCollectionFieldsFilter.vue"
import { state } from "@/utils/state"
import { useStore } from "vuex"

export default defineComponent({
  components: {
    ComputerGroupsDropdown,
    CustomDataTable,
    ComputerUpdateTime,
    ComputerSyncTime,
    ComputerRunConfiguration,
    LinkLabel,
    ComputerToolkitVersion,
    ToolkitAutoComplete,
    ComputerForm,
    FilterField,
    FilterContextMenu,
    FilterTag,
    ComputerCollectionFieldsFilter,
  },
  setup() {
    let part = 0
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const computerService = new ComputerService()
    const releaseService = new ReleasesService()
    const editVersionOverlayComponent = ref()
    const editToolkitVersionOverlayComponent = ref()
    const targetVersion = ref()
    const targetToolkitVersion = ref()
    const scrollComponent = ref()
    const sort = ref(null)
    const loading = ref(true)
    const loadedAllData = ref(false)
    const loadingPart = ref(false)
    const items = ref([])
    const allItems = reactive([])
    const selectedItems = ref()
    const { absoluteDateTime } = useTimeUtils()
    const windowWidth = ref(window.innerWidth)
    const newComputerDialog = ref(false)
    const commentsEnabled = process.env.VUE_APP_RUN_CONFIG_COMMENTS == "true"
    const currentVersionOptions = ref([])
    const filterContextMenu = ref()
    const fieldTypeToFilter = ref("")
    const fieldValueToFilter = ref("")
    const activeFilters = reactive([])
    const activeSorting = reactive({
      sortField: "",
      sortType: "",
      order: "",
      path: null,
    })
    const versionFields = ref(["currentversion", "targetversion", "toolkitversion"])
    const filterColumnOverlay = ref()

    const showSortOptions = computed(() => windowWidth.value < 960)
    const scrollable = computed(() => windowWidth.value > 960)

    const sortOptions = ref([
      { label: "Serial ↓", value: "serial,asc" },
      { label: "Serial ↑", value: "serial,desc" },
      { label: "Alias ↓", value: "alias,asc" },
      { label: "Alias ↑", value: "alias,desc" },
      { label: "Sync time ↑", value: "syncTime,desc" },
      { label: "Sync time ↓", value: "syncTime,asc" },
      { label: "Last updated ↑", value: "versionUpdateTime,desc" },
      { label: "Last updated ↓", value: "versionUpdateTime,asc" },
      { label: "Current Version ↓", value: "currentVersion,asc" },
      { label: "Current Version ↑", value: "currentVersion,desc" },
      { label: "Target Version ↓", value: "targetVersion,asc" },
      { label: "Target Version ↑", value: "targetVersion,desc" },
      { label: "Toolkit Version ↓", value: "toolkitVersion,asc" },
      { label: "Toolkit Version ↑", value: "toolkitVersion,desc" },
    ])

    const comparators: any = reactive({
      versionGreater: (a: string, b: string) =>
        a ? a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" }) === 1 : false,
      versionSmaller: (a: string, b: string) =>
        a ? a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" }) === -1 : false,
      versionGreaterOrEqual: (a: string, b: string) =>
        comparators.versionGreater(a, b) || comparators.equals(a, b),
      versionSmallerOrEqual: (a: string, b: string) =>
        comparators.versionSmaller(a, b) || comparators.equals(a, b),
      equals: (a: string, b: string) => a === b,
      subString: (a: string, b: string) =>
        a ? a.toLowerCase().indexOf(b.toLowerCase()) !== -1 : null,
    })

    const sortComparators: any = reactive({
      stringCompare: (a: string, b: string, order: string) => {
        const orderDefine = order === "asc" ? 1 : -1
        if (!a) return -1 * orderDefine
        if (!b) return 1 * orderDefine
        if (a.toString().toLowerCase() > b.toString().toLowerCase()) {
          return 1 * orderDefine
        }
        if (a.toString().toLowerCase() < b.toString().toLowerCase()) {
          return -1 * orderDefine
        }
        return 0
      },
      versionCompare: (a: string, b: string, order: string) => {
        const orderDefine = order === "asc" ? 1 : -1
        if (!a) return -1 * orderDefine
        if (!b) return 1 * orderDefine
        return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" }) * orderDefine
      },
    })

    const contextMenuItems = ref([
      {
        label: "higher than",
        icon: "codeGreaterThan",
        filter() {
          activeFilters.push({
            field: fieldTypeToFilter.value,
            type: "versionGreater",
            compareValue: fieldValueToFilter.value,
            path: (row: any) => row[fieldTypeToFilter.value]?.version,
            operator: ">",
            hasTag: true,
          })
          if (filterContextMenu.value) {
            filterContextMenu.value.hideContextMenu()
          }
          reloadFrontend()
        },
      },
      {
        label: "higher than or equals",
        icon: "codeGreaterThanOrEqual",
        filter() {
          activeFilters.push({
            field: fieldTypeToFilter.value,
            type: "versionGreaterOrEqual",
            compareValue: fieldValueToFilter.value,
            path: (row: any) => row[fieldTypeToFilter.value]?.version,
            operator: ">=",
            hasTag: true,
          })
          if (filterContextMenu.value) {
            filterContextMenu.value.hideContextMenu()
          }
          reloadFrontend()
        },
      },
      {
        label: "is",
        icon: "equalBox",
        filter() {
          activeFilters.push({
            field: fieldTypeToFilter.value,
            type: "equals",
            compareValue: fieldValueToFilter.value,
            path: (row: any) => row[fieldTypeToFilter.value]?.version,
            operator: "=",
            hasTag: true,
          })
          if (filterContextMenu.value) {
            filterContextMenu.value.hideContextMenu()
          }
          reloadFrontend()
        },
      },
      {
        label: "lower than or equals",
        icon: "codeLessThanOrEqual",
        filter() {
          activeFilters.push({
            field: fieldTypeToFilter.value,
            type: "versionSmallerOrEqual",
            compareValue: fieldValueToFilter.value,
            path: (row: any) => row[fieldTypeToFilter.value]?.version,
            operator: "<=",
            hasTag: true,
          })
          if (filterContextMenu.value) {
            filterContextMenu.value.hideContextMenu()
          }
          reloadFrontend()
        },
      },
      {
        label: "lower than",
        icon: "codeLessThan",
        filter() {
          activeFilters.push({
            field: fieldTypeToFilter.value,
            type: "versionSmaller",
            compareValue: fieldValueToFilter.value,
            path: (row: any) => row[fieldTypeToFilter.value]?.version,
            operator: "<",
            hasTag: true,
          })
          if (filterContextMenu.value) {
            filterContextMenu.value.hideContextMenu()
          }
          reloadFrontend()
        },
      },
    ])

    if (route.params.sortOptions) {
      const routeSortOptions = route.params.sortOptions
      sort.value = sortOptions.value.filter(
        (sortOption) => sortOption.value === routeSortOptions
      )[0]

      defineActiveSorting(
        (routeSortOptions as string).split(",")[0],
        (routeSortOptions as string).split(",")[1]
      )
    }

    function defineActiveSorting(sortField: string, order: string) {
      activeSorting.sortField = sortField
      activeSorting.order = order
      activeSorting.sortType = versionFields.value.includes(sortField.toLowerCase())
        ? "versionCompare"
        : "stringCompare"
      activeSorting.path = (row: any) => {
        if (versionFields.value.includes(sortField.toLowerCase())) {
          return row.data[sortField]?.version
        } else {
          return row.data[sortField]
        }
      }
    }

    loadPart(0)

    onMounted(() => {
      loading.value = false
      scrollComponent.value = document.querySelector(".p-datatable-wrapper")
      if (scrollComponent.value) {
        scrollComponent.value.addEventListener("scroll", checkVisible, { passive: true })
      }

      window.addEventListener("resize", checkVisible, { passive: true })
    })

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener("scroll", checkVisible)
      }

      window.removeEventListener("resize", checkVisible)
    })

    function checkVisible() {
      windowWidth.value = window.innerWidth
      if (loadedAllData.value) return
      if (loadingPart.value) return

      if (
        Math.ceil(scrollComponent.value.scrollTop * 1.3) >=
        scrollComponent.value.scrollHeight - scrollComponent.value.clientHeight
      ) {
        loadPart(part)
      }
    }

    function loadPart(part: Number) {
      loadingPart.value = true
      computerService.getComputers(part, "", "").then((result: ResultSet<any>) => {
        onPartLoaded(result)
      })
    }

    function onPartLoaded(result: ResultSet<any>) {
      loadingPart.value = false
      loadedAllData.value = !result.moreToCome

      if (result.rows.length == 0) {
        return
      }

      part++
      result.rows.map(function (element) {
        let item = { selected: false, data: element }
        items.value.push(item)
        allItems.push(item)
      })

      if (activeFilters.length) {
        items.value = items.value.filter(filterList)
      }

      if (activeSorting.sortField) {
        items.value = items.value.sort(sortList)
      }

      nextTick().then(() => {
        checkVisible()
      })
    }

    function reload() {
      loadingPart.value = true
      part = 0
      selectedItems.value = null
      loadedAllData.value = false
      items.value = []
      allItems.length = 0
      loadPart(part)
    }

    function reloadFrontend() {
      loadingPart.value = true
      selectedItems.value = null
      items.value = allItems

      if (activeFilters.length) {
        items.value = items.value.filter(filterList)
      }

      if (activeSorting.sortField) {
        items.value = items.value.sort(sortList)
      }
      loadingPart.value = false
    }

    const openComputer = (event: any) => {
      router.push({
        name: "computerProfile",
        params: { computer_id: items.value[event.index].data.id },
      })
    }

    const sortColumn = (event: any) => {
      if (event.value) {
        defineActiveSorting(event.value.value.split(",")[0], event.value.value.split(",")[1])
        router.push({
          name: "computersSorted",
          params: { sortOptions: activeSorting.sortField + "," + activeSorting.order },
        })
      } else {
        defineActiveSorting(event.sortField, event.sortOrder === 1 ? "asc" : "desc")
        router.push({
          name: "computersSorted",
          params: { sortOptions: activeSorting.sortField + "," + activeSorting.order },
        })
      }

      reloadFrontend()
    }

    function onNew() {
      newComputerDialog.value = true
    }

    function onEditRunConfig(event: any) {
      editVersionOverlayComponent.value.toggle(event)
    }

    function onCancelEditRunConfig() {
      editVersionOverlayComponent.value.toggle()
    }

    function onConfirmEditRunConfig() {
      let idsToEdit = selectedItems.value.map((item: any) => item.data.id)

      computerService.setTargetRunConfiguration(targetVersion.value.version, idsToEdit).then(() => {
        reload()
        editVersionOverlayComponent.value.toggle()
      })
    }

    function onEditToolkitVersion(event: any) {
      editToolkitVersionOverlayComponent.value.toggle(event)
    }

    function onCancelEditToolkitVersion() {
      editToolkitVersionOverlayComponent.value.toggle()
    }

    function onConfirmEditToolkitVersion() {
      let idsToEdit = selectedItems.value.map((item: any) => item.data.id)

      computerService
        .setTargetToolkitVersion(targetToolkitVersion.value.version, idsToEdit)
        .then(() => {
          editToolkitVersionOverlayComponent.value.toggle()
          reload()
        })
    }

    function onCreateComputer(organization: any, company: any = null) {
      computerService.addComputer(organization, company ? company.id : null).then((result) => {
        router.push({ name: "computerProfile", params: { computer_id: result } })
      })
    }

    function onCancelCreate() {
      newComputerDialog.value = false
    }

    function loadAllCurrentVersions() {
      releaseService.getAllRunConfigurations(!commentsEnabled).then((response) => {
        currentVersionOptions.value = response.map((res: any) => res.version)
      })
    }

    const selectedColumns = computed(() =>
      store.getters.getComputerColumns
        .filter((column: any) => column.selected)
        .map((column: any) => column.name)
    )

    const columnsObject = reactive([
      { name: "Serial", selected: selectedColumns.value?.includes("Serial") },
      { name: "Organization", selected: selectedColumns.value?.includes("Organization") },
      { name: "Alias", selected: selectedColumns.value?.includes("Alias") },
      { name: "System Mode", selected: selectedColumns.value?.includes("System Mode") },
      { name: "Panel", selected: selectedColumns.value?.includes("Panel") },
      { name: "Generator", selected: selectedColumns.value?.includes("Generator") },
      { name: "SIM card", selected: selectedColumns.value?.includes("SIM card") },
      { name: "Sync Time", selected: selectedColumns.value?.includes("Sync Time") },
      { name: "Update Time", selected: selectedColumns.value?.includes("Update Time") },
      { name: "Beta tag", selected: selectedColumns.value?.includes("Beta tag") },
      { name: "Toolkit Version", selected: selectedColumns.value?.includes("Toolkit Version") },
      { name: "Version", selected: selectedColumns.value?.includes("Version") },
    ])

    const selectAllColumns = ref(
      columnsObject.filter((column) => column.selected).length === columnsObject.length
    )

    function filterColumns(event: any) {
      filterColumnOverlay.value.toggle(event)
    }

    function updateSelectedColumns() {
      store.dispatch("setComputerColumns", columnsObject)
      selectAllColumns.value =
        columnsObject.filter((column) => column.selected).length === columnsObject.length
    }

    function updateAllColumns() {
      columnsObject.forEach((column) => {
        column.selected = selectAllColumns.value
      })
      updateSelectedColumns()
    }

    /*watch(
      () => selectedColumns.value.length,
      () => {
        store.dispatch("setComputerColumns", selectedColumns.value)
      }
    )*/

    const showContextMenu = (event: any, fieldType: string, fieldValue: string) => {
      fieldTypeToFilter.value = fieldType
      fieldValueToFilter.value = fieldValue
      if (fieldValue) {
        filterContextMenu.value.toggleContextMenu(event)
      }
    }

    const filterList = (row: any) => {
      return activeFilters
        .map((filter: any) => {
          return !!comparators[filter.type](filter.path(row.data), filter.compareValue)
        })
        .every((isTrue) => isTrue)
    }

    function removeFilter(filter: any) {
      activeFilters.splice(activeFilters.indexOf(filter), 1)
      reloadFrontend()
    }

    const sortList = (a: any, b: any) => {
      return sortComparators[activeSorting.sortType](
        activeSorting.path(a),
        activeSorting.path(b),
        activeSorting.order
      )
    }

    const goToOrg = function (org: any) {
      router.push({ name: "organization", params: { organization_id: org.id } })
    }

    return {
      sortOptions,
      editVersionOverlayComponent,
      selectedItems,
      targetVersion,
      onEditRunConfig,
      onCancelEditRunConfig,
      onConfirmEditRunConfig,
      loadPart,
      onNew,
      openComputer,
      sortColumn,
      items,
      loading,
      absoluteDateTime,
      reload,
      reloadFrontend,
      showSortOptions,
      sort,
      editToolkitVersionOverlayComponent,
      targetToolkitVersion,
      onEditToolkitVersion,
      onCancelEditToolkitVersion,
      onConfirmEditToolkitVersion,
      newComputerDialog,
      onCreateComputer,
      onCancelCreate,
      loadAllCurrentVersions,
      currentVersionOptions,
      columnsObject,
      selectedColumns,
      contextMenuItems,
      filterContextMenu,
      showContextMenu,
      scrollable,
      activeFilters,
      removeFilter,
      state,
      goToOrg,
      filterColumnOverlay,
      filterColumns,
      updateSelectedColumns,
      updateAllColumns,
      selectAllColumns,
    }
  },
})
