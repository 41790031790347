
import { ref } from "vue"

export default {
  name: "FilterContextMenu",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const menu = ref()

    function toggleContextMenu(event: PointerEvent) {
      if (event) {
        menu.value.show(event)
      }
    }

    function hideContextMenu() {
      menu.value.hide()
    }

    return {
      menu,
      toggleContextMenu,
      hideContextMenu,
    }
  },
}
