
import Dropdown from "primevue/dropdown"

export default {
  name: "FilterField",
  props: {
    modelValue: String,
    filterKey: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  emits: ["load-options", "update:modelValue"],
  setup(props: any, { emit }: any) {
    emit("load-options")
  },
}
