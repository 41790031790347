<template>
  <TreeSelect
    v-model="modelValue"
    :options="options"
    placeholder="Select computer groups"
    display="chip"
    selection-mode="checkbox"
  />
</template>

<script>
export default {
  name: "ComputerGroupsDropdown",
  props: {
    modelValue: {
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const options = ["test", "test2", "test3"]

    return {
      options,
    }
  },
}
</script>
