import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f5f94b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_ContextMenu = _resolveComponent("ContextMenu")!

  return (_openBlock(), _createBlock(_component_ContextMenu, {
    ref: "menu",
    model: $props.items
  }, {
    item: _withCtx(({ item }) => [
      _createElementVNode("div", {
        onClick: ($event: any) => (item.filter()),
        class: "context-menu-item"
      }, [
        (item.icon)
          ? (_openBlock(), _createBlock(_component_mdicon, {
              key: 0,
              name: item.icon,
              size: 22,
              class: "mr-1"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString(item.label), 1)
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["model"]))
}