
import { computed } from "vue"

export default {
  name: "FilterTag",
  props: {
    field: {
      type: String,
      default: "",
    },
    value: {
      type: String,
    },
    operator: {
      type: String,
    },
  },
  emits: ["remove-filter"],
  setup(props: any, { emit }: any) {
    const icon = computed(() => {
      switch (props.operator) {
        case "<":
          return "codeLessThan"
        case "<=":
          return "codeLessThanOrEqual"
        case "=":
          return "equalBox"
        case ">=":
          return "codeGreaterThanOrEqual"
        case ">":
          return "codeGreaterThan"
        default:
          return null
      }
    })

    function removeTag() {
      emit("remove-filter")
    }

    return {
      removeTag,
      icon,
    }
  },
}
